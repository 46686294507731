import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { onMounted } from "vue";
import Datepicker from "Datepicker.js";
import "Datepicker.js/dist/css/datepicker.minimal.css";
import moment from "moment";


export default /*@__PURE__*/_defineComponent({
  __name: 'DatePickerButton',
  props: {
    ranged: { type: Boolean }
  },
  emits: ["change"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const id = (Math.random() + 1).toString(36).substring(7);
const placeholder = `Select ${props.ranged ? "date ranges" : "date"}`;

onMounted(() => {
  new Datepicker("#" + id, {
    separator: " - ",
    ranged: props.ranged,
    serialize: (date: Date) => moment(date).format("D MMM YYYY"),
    onChange: (selectedDates: Date[] | Date | undefined) => {
      if (selectedDates instanceof Date) {
        emit("change", selectedDates);
      } else if (selectedDates?.length > 1) {
        emit("change", [
          selectedDates[0],
          selectedDates[selectedDates.length - 1],
        ]);
      }
    },
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      class: "_input",
      id: _unref(id),
      type: "text",
      placeholder: placeholder
    }, null, 8, _hoisted_1),
    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-regular fa-calendar" }, null, -1))
  ]))
}
}

})