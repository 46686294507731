import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "fas fa-sort-up sort-icon"
}
const _hoisted_5 = {
  key: 1,
  class: "fas fa-sort-down sort-icon"
}
const _hoisted_6 = {
  key: 2,
  class: "fas fa-sort disabled sort-icon"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createElementBlock("th", {
              key: column.key,
              onClick: ($event: any) => (_ctx.sortTable(column.key)),
              style: _normalizeStyle({ width: column.width }),
              class: "sortable-column"
            }, [
              _createTextVNode(_toDisplayString(column.label) + " ", 1),
              _createElementVNode("span", null, [
                (_ctx.sortColumn === column.key && _ctx.sortOrder === 'asc')
                  ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                  : (_ctx.sortColumn === column.key && _ctx.sortOrder === 'desc')
                    ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                    : (_openBlock(), _createElementBlock("i", _hoisted_6))
              ])
            ], 12, _hoisted_3))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("td", {
                key: column.key
              }, [
                (_ctx.isHtml(row[column.key]))
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      innerHTML: row[column.key]
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(row[column.key]), 1))
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}