
import { defineComponent, PropType } from "vue";
import DropdownSelect from "@/components/Dropdown.vue";

interface Option {
  key: string;
  value: string;
  text: string;
}

export default defineComponent({
  name: "PaginationBar",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    startRecords: {
      type: Number,
      required: true,
    },
    endRecords: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localPage: this.currentPage.toString(),
      localPageSize: this.pageSize.toString(),
      rowOptions: [
        { key: "ten", value: "10", text: "10" },
        { key: "twentyfive", value: "25", text: "25" },
        { key: "fifhy", value: "50", text: "50" },
      ],
    };
  },
  components: {
    DropdownSelect,
  },
  computed: {
    startRecord(): number {
      return (this.localPage - 1) * this.localPageSize + 1;
    },
    endRecord(): number {
      return Math.min(this.localPage * this.localPageSize, this.totalRecords);
    },
    hasPrevPage(): boolean {
      return this.localPage > 1;
    },
    hasNextPage(): boolean {
      return this.localPage < this.totalPages;
    },
    pageOptions(): Option[] {
      return Array.from({ length: this.totalPages }, (_, i) => ({
        key: `page-${i + 1}`,
        value: (i + 1).toString(),
        text: (i + 1).toString(),
      }));
    },
  },
  methods: {
    changePageSize() {
      this.$emit("changePageSize", this.localPageSize);
    },
    changeSpecificPage() {
      this.$emit("changeSpecificPage", this.localPage);
    },
    prevPage() {
      if (this.hasPrevPage) {
        this.localPage -= 1;
        this.$emit("prevPage", this.localPage);
      }
    },
    nextPage() {
      if (this.hasNextPage) {
        this.localPage += 1;
        this.$emit("nextPage", this.localPage);
      }
    },
  },
  watch: {
    currentPage(newPage) {
      this.localPage = newPage;
    },
    pageSize(newSize) {
      this.localPageSize = newSize;
    },
    changeSpecificPage(newSize) {
      this.localPage = newSize;
    },
    changePageSize(newSize) {
      this.localPageSize = newSize;
    },
  },
});
