import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/error/5xx.png'
import _imports_1 from '@/assets/images/no_data_medium.png'
import _imports_2 from '@/assets/images/user_not_found_medium.png'


const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "menu-container" }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = { class: "header-section" }
const _hoisted_5 = { class: "header-section" }
const _hoisted_6 = {
  key: 0,
  class: "no-data-container"
}
const _hoisted_7 = {
  key: 1,
  class: "no-data-container"
}
const _hoisted_8 = {
  key: 2,
  class: "no-data-container"
}
const _hoisted_9 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePickerButton = _resolveComponent("DatePickerButton")!
  const _component_DropdownCheckbox = _resolveComponent("DropdownCheckbox")!
  const _component_AllFilters = _resolveComponent("AllFilters")!
  const _component_ExportLogButton = _resolveComponent("ExportLogButton")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!
  const _component_PaginationBar = _resolveComponent("PaginationBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "page-title" }, [
      _createElementVNode("h1", null, "Activity Logs")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_DatePickerButton, {
              onChange: _ctx.onFilterDate,
              ranged: true,
              style: { width: '14rem' }
            }, null, 8, ["onChange"]),
            _createVNode(_component_DropdownCheckbox, {
              modelValue: _ctx.filter.type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.type) = $event)),
              options: _ctx.typeOptions,
              onChange: _ctx.changeType,
              disableFirstOption: false
            }, null, 8, ["modelValue", "options", "onChange"]),
            _createVNode(_component_AllFilters, {
              modelValue: _ctx.filter,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event)),
              onApplyAllFilters: _ctx.allFilter,
              "company-list": _ctx.companies,
              "api-filter": _ctx.filter,
              "filter-list-data": {
                type: _ctx.typeOptions,
                date: {
                  startDate: _ctx.filter.startDate,
                  endDate: _ctx.filter.endDate,
                },
                page: _ctx.pageOptions,
                company: _ctx.companies,
              }
            }, null, 8, ["modelValue", "onApplyAllFilters", "company-list", "api-filter", "filter-list-data"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ExportLogButton, { "company-list": _ctx.companies }, null, 8, ["company-list"]),
            _createVNode(_component_SearchInput, {
              modelValue: _ctx.filter.search,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.search) = $event)),
              placeholder: "Search Developer",
              onInput: _ctx.checkSearchQueryLength
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "No Data",
                class: "no-data-image"
              }, null, -1),
              _createElementVNode("p", null, [
                _createElementVNode("b", null, "There is an error on our side")
              ], -1),
              _createElementVNode("p", null, "Please reload this page or try again later.", -1)
            ])))
          : (!_ctx.hasData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "No Data",
                  class: "no-data-image"
                }, null, -1),
                _createElementVNode("p", null, [
                  _createElementVNode("b", null, "No activity yet")
                ], -1),
                _createElementVNode("p", null, "Once you perform actions, they will appear here.", -1)
              ])))
            : (!_ctx.hasFound)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [
                  _createElementVNode("img", {
                    src: _imports_2,
                    alt: "No Data",
                    class: "no-data-image"
                  }, null, -1),
                  _createElementVNode("p", null, [
                    _createElementVNode("b", null, "No developer found")
                  ], -1),
                  _createElementVNode("p", null, "Recheck the keywords you have typed and try searching again.", -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_DynamicTable, {
                      columns: _ctx.columns,
                      data: _ctx.activityLogEdited,
                      filterData: _ctx.filter,
                      onApplySort: _ctx.sortActivityLog
                    }, null, 8, ["columns", "data", "filterData", "onApplySort"])
                  ]),
                  _createVNode(_component_PaginationBar, {
                    currentPage: _ctx.currentPage,
                    totalPages: _ctx.totalPages,
                    pageSize: _ctx.currentSize,
                    totalRecords: _ctx.totalRecords,
                    startRecords: _ctx.startRecord,
                    endRecords: _ctx.endRecord,
                    onChangePageSize: _ctx.changePageSize,
                    onChangeSpecificPage: _ctx.changeSpecificPage,
                    onPrevPage: _ctx.prevPage,
                    onNextPage: _ctx.nextPage
                  }, null, 8, ["currentPage", "totalPages", "pageSize", "totalRecords", "startRecords", "endRecords", "onChangePageSize", "onChangeSpecificPage", "onPrevPage", "onNextPage"])
                ]))
      ])
    ])
  ]))
}