import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pagination-controls" }
const _hoisted_2 = { class: "page-size" }
const _hoisted_3 = { class: "pagination-info" }
const _hoisted_4 = { class: "pagination" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownSelect = _resolveComponent("DropdownSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { for: "pageSize" }, "Rows per page:", -1)),
      _createVNode(_component_DropdownSelect, {
        modelValue: _ctx.localPageSize,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPageSize) = $event)),
        options: _ctx.rowOptions,
        onChange: _ctx.changePageSize,
        disableFirstOption: false
      }, null, 8, ["modelValue", "options", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_3, " Showing " + _toDisplayString(_ctx.startRecords) + "-" + _toDisplayString(_ctx.endRecords) + " of " + _toDisplayString(_ctx.totalRecords), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args))),
        disabled: !_ctx.hasPrevPage
      }, "Previous", 8, _hoisted_5),
      _createElementVNode("span", null, [
        _cache[5] || (_cache[5] = _createTextVNode(" Page ")),
        _createVNode(_component_DropdownSelect, {
          modelValue: _ctx.localPage,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localPage) = $event)),
          options: _ctx.pageOptions,
          onChange: _ctx.changeSpecificPage,
          disableFirstOption: false
        }, null, 8, ["modelValue", "options", "onChange"]),
        _createTextVNode(" of " + _toDisplayString(_ctx.totalPages), 1)
      ]),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
        disabled: !_ctx.hasNextPage
      }, "Next", 8, _hoisted_6)
    ])
  ]))
}