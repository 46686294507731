import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = { class: "search-wrapper" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localQuery) = $event)),
        placeholder: _ctx.placeholder,
        class: "search-input",
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.localQuery]
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-search search-icon" }, null, -1))
    ])
  ]))
}