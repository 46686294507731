import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "_modal-title" }
const _hoisted_3 = { class: "_wrapper" }
const _hoisted_4 = { class: "_modal-content" }
const _hoisted_5 = { class: "_modal-action" }

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    width: {},
    header: {},
    isModalOpen: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const modalOpen = ref<boolean>(props.isModalOpen);

return (_ctx: any,_cache: any) => {
  return (_ctx.isModalOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "_backdrop" }, null, -1)),
        _createElementVNode("div", {
          class: "_modal",
          style: _normalizeStyle({ width: _ctx.width })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", null, _toDisplayString(props.header), 1),
            _createElementVNode("button", {
              class: "_btn _icon-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "fa-solid fa-xmark" }, null, -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "body")
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "action")
            ])
          ])
        ], 4)
      ]))
    : _createCommentVNode("", true)
}
}

})