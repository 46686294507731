
import { defineComponent, PropType } from "vue";

interface Option {
  key: string;
  value: string;
  text: string;
}

export default defineComponent({
  name: "DropdownSelect",
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    disableFirstOption: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    onChange(event: Event) {
      const value = (event.target as HTMLSelectElement).value;
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
    },
  },
});
