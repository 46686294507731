
import { defineComponent, PropType } from "vue";

interface Column {
  key: string;
  label: string;
  width?: string;
}

interface Row {
  [key: string]: any;
}

interface Filter {
  companyName: string;
  pageName: string;
  type: string;
  search: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
  sortColumn: string;
}

export default defineComponent({
  name: "DynamicTable",
  props: {
    columns: {
      type: Array as PropType<Column[]>,
      required: true,
    },
    data: {
      type: Array as PropType<Row[]>,
      required: true,
    },
    filterData: {
      type: Object as PropType<Filter>,
      required: true,
    },
  },
  data() {
    return {
      sortColumn: "",
      sortOrder: "asc",
    };
  },
  methods: {
    sortTable(column: string) {
      if (this.sortColumn === column) {
        if (this.sortOrder === "asc") {
          this.sortOrder = "desc";
        } else if (this.sortOrder === "desc") {
          this.sortOrder = "";
          this.sortColumn = "";
        } else {
          this.sortOrder = "asc";
        }
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }
      const updatedFilterData = {
        ...this.filterData,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
      };
      this.$emit("apply-sort", updatedFilterData);
    },
    isHtml(content: string): boolean {
      const div = document.createElement("div");
      div.innerHTML = content;
      return div.children.length > 0;
    },
  },
});
