
import { defineComponent } from "vue";
import { ActivityLogEntry } from "../../../interfaces"; // Adjust the import path as necessary
import { COMPANY_FETCH_COMPANIES, FETCH_ACTIVITY_LOGS } from "@/store-types";
import moment from "moment";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import {
  convertToListString,
  convertUnixTimestampToDate,
} from "../../../utils/util";
import ExportLogButton from "@/components/ExportLogButton.vue";
import DynamicTable from "@/components/Table.vue";
import PaginationBar from "@/components/PaginationBar.vue";
import SearchInput from "@/components/SearchInput.vue";
import DatePickerButton from "@/components/DatePickerButton.vue";
import AllFilters from "@/components/AllFilters.vue";
import DropdownCheckbox from "@/components/DropdownCheckbox.vue";

export default defineComponent({
  components: {
    ExportLogButton,
    DynamicTable,
    PaginationBar,
    SearchInput,
    DatePickerButton,
    DropdownCheckbox,
    AllFilters,
  },
  data() {
    return {
      filter: {
        companyName: "",
        pageName: "",
        type: "",
        search: "",
        startDate: "",
        endDate: "",
        sortOrder: "",
        sortColumn: "",
      },
      activityLogs: [] as ActivityLogEntry[],
      pagination: {
        page: 1,
        size: 10,
        next: 0,
        prev: 0,
        total_page: 0,
        total_record: 0,
      },
      sortColumn: "",
      sortOrder: "asc",
      selectedDate: null, // Add selected date data property
      hasData: true, // Add hasData data property
      hasFound: true,
      hasError: false,
      typeOptions: [
        { key: "all", value: "", text: "All Type", checked: false },
        { key: "create", value: "create", text: "Create", checked: false },
        { key: "update", value: "update", text: "Update", checked: false },
        { key: "delete", value: "delete", text: "Delete", checked: false },
        {
          key: "regenerate",
          value: "regenerate",
          text: "Regenerate",
          checked: false,
        },
      ],
      columns: [
        { key: "date", label: "Date", width: "10%" },
        { key: "type", label: "Type", width: "10%" },
        { key: "page", label: "Page", width: "10%" },
        { key: "companyName", label: "Company Name", width: "20%" },
        { key: "user", label: "Developer", width: "15%" },
        { key: "details", label: "Activity", width: "35%" },
      ],
      pageOptions: [
        {
          key: "applications",
          value: "applications",
          text: "Applications",
          checked: false,
        },
      ],
    };
  },
  mounted() {
    this.loadActivityLog();
    this.fetchCompanies();
  },
  watch: {
    filter() {
      this.loadActivityLog();
    },
    "filter.search": "searchDeveloper", // Watch filter.search and call searchDeveloper on change
  },
  methods: {
    onFilterDate(dates: Date[]) {
      this.filter = {
        startDate: moment(dates[0]).format("YYYY-MM-DD"),
        endDate: moment(dates[1]).format("YYYY-MM-DD"),
      };
    },
    async fetchCompanies() {
      if (this.isFetchingCompanies) {
        return;
      }

      this.isFetchingCompanies = true;

      try {
        await this.$store.dispatch(COMPANY_FETCH_COMPANIES);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isFetchingCompanies = false;
      }
    },
    formatDate(created_at: string): string {
      return moment(String(created_at)).format("MMMM Do, YYYY");
    },
    checkSearchQueryLength(event: Event) {
      const input = event.target as HTMLInputElement;
      this.filter.type = ""; // Reset filter.type when search query changes
      if (input.value.length > 50) {
        this.$store.dispatch(SHOW_TOAST, {
          message: "Search query cannot exceed 50 characters.",
          type: ToastType.ERROR,
        });
      }
    },
    async loadActivityLog() {
      try {
        await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
          page: 1,
          size: 10,
          ...this.filter,
        });
        this.activityLogs = this.$store.state.activity_log.activityLogs;
        this.pagination = this.$store.state.activity_log.pagination;
        this.hasData = this.activityLogs.length > 0;
        this.hasFound = this.activityLogs.length > 0;
      } catch (err) {
        this.handleError(err);
      }
    },
    async sortActivityLog(filter: any) {
      try {
        await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
          page: this.currentPage,
          size: this.currentSize,
          ...filter,
        });
        this.activityLogs = this.$store.state.activity_log.activityLogs;
        this.pagination = this.$store.state.activity_log.pagination;
        if (this.activityLogs.length === 0) {
          this.hasData = false;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async searchDeveloper() {
      if (this.filter.search.length <= 50 && this.hasData && !this.hasError) {
        try {
          await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
            page: 1,
            size: 10,
            ...this.filter,
          });
          this.activityLogs = this.$store.state.activity_log.activityLogs;
          this.pagination = this.$store.state.activity_log.pagination;
          this.hasFound = this.activityLogs.length > 0;
          this.hasData = true;
        } catch (err) {
          this.handleError(err);
        }
      }
    },
    scopes(scopeList: any): string {
      return convertToListString(scopeList);
    },
    unixTimestampToDate(timestamp: number): string {
      return convertUnixTimestampToDate(timestamp);
    },
    typeOnly(type: string): string {
      const typeName = type.split(":");
      return typeName[1];
    },
    async prevPage() {
      if (this.hasPrevPage) {
        try {
          await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
            page: this.currentPage - 1,
            size: this.currentSize,
            ...this.filter,
          });
          this.activityLogs = this.$store.state.activity_log.activityLogs;
          this.pagination = this.$store.state.activity_log.pagination;
        } catch (err) {
          this.handleError(err);
        }
      }
    },
    async nextPage() {
      if (this.hasNextPage) {
        try {
          await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
            page: this.currentPage + 1,
            size: this.currentSize,
            ...this.filter,
          });
          this.activityLogs = this.$store.state.activity_log.activityLogs;
          this.pagination = this.$store.state.activity_log.pagination;
        } catch (err) {
          this.handleError(err);
        }
      }
    },
    async changePageSize(pageSize: number) {
      try {
        await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
          page: 1,
          size: pageSize,
          ...this.filter,
        });
        this.activityLogs = this.$store.state.activity_log.activityLogs;
        this.pagination = this.$store.state.activity_log.pagination;
        if (this.activityLogs.length > 0) {
          this.hasData = true;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async changeSpecificPage(page: number) {
      try {
        await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
          page: page,
          size: this.currentSize,
          ...this.filter,
        });
        this.activityLogs = this.$store.state.activity_log.activityLogs;
        this.pagination = this.$store.state.activity_log.pagination;
        if (this.activityLogs.length > 0) {
          this.hasData = true;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async changeType() {
      try {
        await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
          page: this.currentPage,
          size: this.currentSize,
          ...this.filter,
        });
        this.activityLogs = this.$store.state.activity_log.activityLogs;
        this.pagination = this.$store.state.activity_log.pagination;
        if (this.activityLogs.length == 0) {
          this.hasData = false;
        } else {
          this.hasData = true;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async allFilter() {
      try {
        await this.$store.dispatch(FETCH_ACTIVITY_LOGS, {
          page: this.currentPage,
          size: this.currentSize,
          ...this.filter,
        });
        this.activityLogs = this.$store.state.activity_log.activityLogs;
        this.pagination = this.$store.state.activity_log.pagination;
        if (this.activityLogs.length == 0) {
          this.hasData = false;
        } else {
          this.hasData = true;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    sortTable(column: string) {
      if (this.sortColumn === column) {
        if (this.sortOrder === "asc") {
          this.sortOrder = "desc";
        } else if (this.sortOrder === "desc") {
          this.sortOrder = "";
          this.sortColumn = "";
        } else {
          this.sortOrder = "asc";
        }
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }
    },
    getColumnValue(activityLog: ActivityLogEntry, column: string): any {
      switch (column) {
        case "date":
          return activityLog.date;
        case "type":
          return activityLog.type;
        case "page":
          return activityLog.page;
        case "companyName":
          return activityLog.attributes.company.name;
        case "user":
          return activityLog.user.full_name;
        case "details":
          return activityLog.page; // Adjust this as needed
        default:
          return "";
      }
    },
    handleError(err: Error | any) {
      this.hasError = true;
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";

      this.$store.dispatch(SHOW_TOAST, {
        message: message,
        type: ToastType.ERROR,
      });
    },
  },
  computed: {
    companies(): { name: string; ssoId: string }[] {
      return this.$store.state.company.companies.map((data) => ({
        name: data.name,
        ssoId: data.id,
      }));
    },
    hasPrevPage(): boolean {
      return this.pagination.prev > 0;
    },
    hasNextPage(): boolean {
      return this.pagination.next > 0;
    },
    currentPage(): number {
      return this.pagination.page;
    },
    currentSize(): number {
      return this.pagination.size;
    },
    totalPages(): number {
      return this.pagination.total_page;
    },
    totalRecords(): number {
      return this.pagination.total_record;
    },
    startRecord(): number {
      return (this.pagination.page - 1) * this.pagination.size + 1;
    },
    endRecord(): number {
      return Math.min(
        this.pagination.page * this.pagination.size,
        this.pagination.total_record
      );
    },
    activityLogEdited(): ActivityLogEntry[] {
      const filteredLogs = this.activityLogs;

      if (filteredLogs.length === 0) {
        return [];
      }
      return filteredLogs.map((log: ActivityLogEntry) => ({
        date: convertUnixTimestampToDate(log.date),
        type: this.typeOnly(log.type),
        page: log.page,
        companyName: log.attributes.company.name,
        user: `${log.user.full_name}<br />${log.user.email}`,
        details: `${log.message}
            <ul>
              <li>
                Application Name: ${log.attributes.application.name}
              </li>
              <li>
                Company Name: ${log.attributes.company.name}
              </li>
              <li>
                 Product: ${
                   log.attributes.application.products &&
                   log.attributes.application.products.length > 1
                     ? log.attributes.application.products.join(", ")
                     : log.attributes.application.products &&
                       log.attributes.application.products.length === 1
                     ? log.attributes.application.products[0]
                     : "No products"
                 }
              </li>
              <li>
                Scopes:
                <div>${this.scopes(log.attributes.application.scopes)}</div>
              </li>
            </ul>`,
      }));
    },
  },
});
