import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  key: 0,
  class: "fa-solid fa-chevron-up sort-icon"
}
const _hoisted_3 = {
  key: 1,
  class: "fa-solid fa-chevron-down sort-icon"
}
const _hoisted_4 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_5 = { class: "dropdown-form" }
const _hoisted_6 = ["value", "id", "onUpdate:modelValue", "onChange"]
const _hoisted_7 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "dropdown-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      _cache[1] || (_cache[1] = _createTextVNode(" Type ")),
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : (_openBlock(), _createElementBlock("i", _hoisted_3))
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("form", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (checkbox, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "form-check",
                key: index
              }, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input",
                  type: "checkbox",
                  value: checkbox.value,
                  id: checkbox.key,
                  "onUpdate:modelValue": ($event: any) => ((checkbox.checked) = $event),
                  onChange: ($event: any) => (_ctx.onChangeType(checkbox))
                }, null, 40, _hoisted_6), [
                  [_vModelCheckbox, checkbox.checked]
                ]),
                _createElementVNode("label", {
                  class: "form-check-label",
                  for: checkbox.key
                }, _toDisplayString(checkbox.text), 9, _hoisted_7)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}