
import { defineComponent, PropType } from "vue";

interface Option {
  key: string;
  value: string;
  text: string;
  checked: boolean;
}

export default defineComponent({
  name: "DropdownSelect",
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    disableFirstOption: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      localValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    onChangeType(checkbox: Option) {
      if (checkbox.key == "all") {
        this.options.forEach((option) => {
          option.checked = checkbox.checked;
        });
      } else {
        const allChecked = this.options.every((option) => option.checked);
        this.options.find((option) => option.key == "all")!.checked =
          allChecked;
      }

      const checkedValues = this.options
        .filter((option) => option.checked && option.key !== "all")
        .map((option) => option.value)
        .join(",");

      this.$emit("update:modelValue", checkedValues);
    },
  },
});
