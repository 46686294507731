import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "sidebar" }
const _hoisted_3 = { class: "_modal-title" }
const _hoisted_4 = { class: "_modal-content" }
const _hoisted_5 = {
  key: 0,
  class: "_row"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "_row" }
const _hoisted_8 = {
  key: 0,
  class: "container-dynamic"
}
const _hoisted_9 = { class: "header" }
const _hoisted_10 = { class: "container text-center" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col col-6 text-left" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "col text-right" }
const _hoisted_15 = { class: "content" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "container-dynamic"
}
const _hoisted_18 = { class: "header" }
const _hoisted_19 = { class: "container text-center" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col text-left" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "col text-right" }
const _hoisted_24 = { class: "content" }
const _hoisted_25 = {
  key: 0,
  class: "form-check-container"
}
const _hoisted_26 = ["value", "onUpdate:modelValue", "onChange"]
const _hoisted_27 = {
  class: "form-check-label",
  for: "flexCheckDefault"
}
const _hoisted_28 = {
  key: 2,
  class: "container-dynamic"
}
const _hoisted_29 = { class: "header" }
const _hoisted_30 = { class: "container text-center" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col text-left" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { class: "col text-right" }
const _hoisted_35 = { class: "content" }
const _hoisted_36 = {
  key: 0,
  class: "form-check-container"
}
const _hoisted_37 = ["value", "onUpdate:modelValue", "onChange"]
const _hoisted_38 = {
  class: "form-check-label",
  for: "flexCheckDefault"
}
const _hoisted_39 = {
  key: 3,
  class: "container-dynamic"
}
const _hoisted_40 = { class: "header" }
const _hoisted_41 = { class: "container text-center" }
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "col text-left" }
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { class: "col text-right" }
const _hoisted_46 = { class: "content" }
const _hoisted_47 = {
  key: 0,
  class: "_row",
  style: {"padding-top":"5px"}
}
const _hoisted_48 = ["value"]
const _hoisted_49 = {
  key: 2,
  style: {"text-align":"center"}
}
const _hoisted_50 = { class: "dropdown" }
const _hoisted_51 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_52 = ["onClick"]
const _hoisted_53 = { class: "_modal-action" }
const _hoisted_54 = { class: "container footer" }
const _hoisted_55 = { class: "row" }
const _hoisted_56 = { class: "col text-left" }
const _hoisted_57 = { key: 0 }
const _hoisted_58 = { class: "col text-right" }

import moment from "moment";
import { computed, ref, watch } from "vue";
import DatePickerButton from "./DatePickerButton.vue";

// props

export default /*@__PURE__*/_defineComponent({
  __name: 'AllFilters',
  props: {
    companyList: {},
    apiFilter: {},
    filterListData: {}
  },
  emits: ["apply-all-filters"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

// state
const isModalOpen = ref(false);
const isDropdownAddFilterOpen = ref(false);
const stateFilterData = ref(props.filterListData);
const stateAPIFilter = ref(props.apiFilter);
const stateFilterCollapsed = ref({
  type: true,
  date: true,
  page: true,
  company: true,
});
const stateMenuFilter = ref({
  type: {
    key: "type",
    title: "Type",
    placeholder: "select type",
    selected: false,
  },
  date: {
    key: "date",
    title: "Date",
    placeholder: "select date",
    selected: false,
  },
  page: {
    key: "page",
    title: "Page",
    placeholder: "select page",
    selected: false,
  },
  company: {
    key: "company",
    title: "Company Name",
    placeholder: "select company",
    selected: false,
  },
});

// computed
const enableReset = computed(() =>
  Object.values(stateMenuFilter.value).every((value) => !value.selected)
);
const enableFilterCompany = computed(
  () =>
    stateAPIFilter.value.companyName !== "" ||
    (stateMenuFilter.value.company.selected &&
      stateAPIFilter.value.companyName === "")
);
const enableFilterType = computed(
  () =>
    (stateMenuFilter.value.type.selected &&
      stateFilterData.value.type.every((item) => !item.checked)) ||
    (stateFilterData.value.type.length > 0 &&
      stateFilterData.value.type.some((item) => item.checked))
);
const enableFilterPage = computed(
  () =>
    (stateFilterData.value.page.length > 0 &&
      stateFilterData.value.page.some((item) => item.checked)) ||
    (stateMenuFilter.value.page.selected &&
      stateFilterData.value.page.every((item) => !item.checked))
);
const enableFilterDate = computed(
  () =>
    (stateFilterData.value.date.startDate !== "" &&
      stateFilterData.value.date.endDate !== "") ||
    (stateMenuFilter.value.date.selected &&
      stateFilterData.value.date.startDate === "" &&
      stateFilterData.value.date.endDate === "")
);
const hideButtonAddFilter = computed(
  () =>
    (Object.values(stateMenuFilter.value).some((value) => !value.selected) &&
      !enableReset.value) ||
    enableReset.value
);

// methods
const updateMenuFilter = (key: string, value: boolean) => {
  stateMenuFilter.value[key].selected = value;
  isDropdownAddFilterOpen.value = false;
};
const toggleCollapsedFilter = (key: string, value: boolean) => {
  stateFilterCollapsed.value[key] = value;
};
const toggleSelectedFilter = (key: string, value: boolean) => {
  stateMenuFilter.value[key].selected = value;
  if (value) {
    stateFilterCollapsed.value[key] = false;
  }

  if (key === "type") {
    resetFilterType();
    stateAPIFilter.value.type = "";
    emit("apply-all-filters", stateAPIFilter.value);
  } else if (key === "page") {
    resetFilterPage();
    stateAPIFilter.value.pageName = "";
    emit("apply-all-filters", stateAPIFilter.value);
  } else if (key === "company") {
    resetCompany();
    emit("apply-all-filters", stateAPIFilter.value);
  } else if (key === "date") {
    resetFilterDate();
    emit("apply-all-filters", stateAPIFilter.value);
  }
};
const updateFilterType = (key: string, value: boolean) => {
  stateFilterData.value.type.find((item) => item.key === key)!.checked = value;
  updatePlaceholderFilterType();
  emit("apply-all-filters", stateAPIFilter.value);
};
const updateFilterPage = (key: string, value: boolean) => {
  stateFilterData.value.page.find((item) => item.key === key)!.checked = value;
  updatePlaceholderFilterPage();
  emit("apply-all-filters", stateAPIFilter.value);
};
const updateFilterCompany = (event: any) => {
  stateAPIFilter.value.companyName = event.target.value;
  updatePlaceholderFilterCompany();
  emit("apply-all-filters", stateAPIFilter.value);
};
const updateFilterDate = (dates: Date[]) => {
  const startDate = moment(dates[0]).format("YYYY-MM-DD");
  const endDate = moment(dates[1]).format("YYYY-MM-DD");
  stateFilterData.value.date.startDate = startDate;
  stateFilterData.value.date.endDate = endDate;
  stateMenuFilter.value.date.placeholder = `between ${startDate} and ${endDate}`;
  stateAPIFilter.value.startDate = startDate;
  stateAPIFilter.value.endDate = endDate;
  emit("apply-all-filters", stateAPIFilter.value);
};
const updatePlaceholderFilterType = () => {
  let joinType = "";
  if (stateFilterData.value.type.some((item) => item.checked)) {
    joinType = stateFilterData.value.type
      .filter((option) => option.checked && option.key !== "all")
      .map((item) => item.text)
      .join(",");
    stateMenuFilter.value.type.placeholder = `is any of ${joinType}`;
  } else {
    stateMenuFilter.value.type.placeholder = "select type";
  }
  stateAPIFilter.value.type = joinType;
};
const updatePlaceholderFilterPage = () => {
  let joinType = "";
  if (stateFilterData.value.page.some((item) => item.checked)) {
    joinType = stateFilterData.value.page
      .filter((option) => option.checked && option.key !== "all")
      .map((item) => item.text)
      .join(",");
    stateMenuFilter.value.page.placeholder = `is any of ${joinType}`;
  } else {
    stateMenuFilter.value.page.placeholder = "select page";
  }
  stateAPIFilter.value.pageName = joinType;
};
const updatePlaceholderFilterCompany = () => {
  if (stateAPIFilter.value.companyName === "") {
    stateMenuFilter.value.company.placeholder = "select company";
  } else {
    stateMenuFilter.value.company.placeholder = `is any of ${stateAPIFilter.value.companyName}`;
  }
};
const resetCompany = () => {
  stateMenuFilter.value.company.placeholder = "select company";
  stateAPIFilter.value.companyName = "";
};
const resetFilterPage = () => {
  stateFilterData.value.page.forEach((item) => (item.checked = false));
  stateMenuFilter.value.page.placeholder = "select page";
  stateAPIFilter.value.pageName = "";
};
const resetFilterType = () => {
  stateFilterData.value.type.forEach((item) => (item.checked = false));
  stateMenuFilter.value.type.placeholder = "select type";
  stateAPIFilter.value.type = "";
};
const resetFilterDate = () => {
  stateFilterData.value.date.startDate = "";
  stateFilterData.value.date.endDate = "";
  stateMenuFilter.value.date.placeholder = "select date";
  stateAPIFilter.value.startDate = "";
  stateAPIFilter.value.endDate = "";
};
const toggleDropdownAddFilter = () => {
  isDropdownAddFilterOpen.value = !isDropdownAddFilterOpen.value;
};
const applyAllFilters = () => {
  emit("apply-all-filters", stateAPIFilter.value);
  isModalOpen.value = false;
};

// watch
watch(enableFilterType, (newValue) => {
  stateMenuFilter.value.type.selected = newValue;
  if (newValue) {
    updatePlaceholderFilterType();
  }
});
watch(enableFilterDate, (newValue) => {
  stateMenuFilter.value.date.selected = newValue;
});
watch(enableFilterPage, (newValue) => {
  stateMenuFilter.value.page.selected = newValue;
  if (newValue) {
    updatePlaceholderFilterPage();
  }
});
watch(enableFilterCompany, (newValue) => {
  stateMenuFilter.value.company.selected = newValue;
  if (newValue) {
    updatePlaceholderFilterCompany();
  }
});

// emit
const emit = __emit;

const toggleReset = () => {
  stateFilterCollapsed.value = {
    type: true,
    date: true,
    page: true,
    company: true,
  };
  stateMenuFilter.value.type.selected = false;
  stateMenuFilter.value.date.selected = false;
  stateMenuFilter.value.page.selected = false;
  stateMenuFilter.value.company.selected = false;

  stateMenuFilter.value.type.placeholder = "select type";
  stateMenuFilter.value.date.placeholder = "select date";
  stateMenuFilter.value.page.placeholder = "select page";
  stateMenuFilter.value.company.placeholder = "select company";

  resetCompany();
  resetFilterDate();
  resetFilterPage();
  resetFilterType();

  emit("apply-all-filters", stateAPIFilter.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isModalOpen.value = true))
    }, "All Filters"),
    (isModalOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[29] || (_cache[29] = _createElementVNode("div", { class: "_backdrop" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[17] || (_cache[17] = _createElementVNode("h1", null, "All Filters", -1)),
              _createElementVNode("button", {
                class: "_icon-button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (isModalOpen.value = false))
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("img", {
                  src: "https://cdn-icons-png.flaticon.com/512/2961/2961937.png",
                  alt: "close"
                }, null, -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_4, [
              (enableReset.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[18] || (_cache[18] = [
                    _createElementVNode("div", { style: {"text-align":"center"} }, [
                      _createElementVNode("i", {
                        class: "fa-solid fa-filter",
                        style: {"font-size":"100px","padding-bottom":"20px"}
                      }),
                      _createElementVNode("p", null, [
                        _createElementVNode("b", null, "No filters have been set yet")
                      ]),
                      _createElementVNode("p", null, "Your filter will be displayed here")
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (enableFilterDate.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                  _createElementVNode("div", _hoisted_12, [
                                    _cache[19] || (_cache[19] = _createElementVNode("label", { for: "date" }, "Date", -1)),
                                    (stateFilterCollapsed.value.date)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(stateMenuFilter.value.date.placeholder), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col" }, null, -1)),
                                  _createElementVNode("div", _hoisted_14, [
                                    _createElementVNode("button", {
                                      class: "fa-regular fa-square-minus",
                                      style: {"padding-right":"10px"},
                                      type: "button",
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => (toggleSelectedFilter('date', false)))
                                    }),
                                    (stateFilterCollapsed.value.date)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          class: "fa-solid fa-chevron-down",
                                          type: "button",
                                          onClick: _cache[3] || (_cache[3] = ($event: any) => (toggleCollapsedFilter('date', false)))
                                        }))
                                      : (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          class: "fa-solid fa-chevron-up",
                                          type: "button",
                                          onClick: _cache[4] || (_cache[4] = ($event: any) => (toggleCollapsedFilter('date', true)))
                                        }))
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              (!stateFilterCollapsed.value.date)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createVNode(DatePickerButton, {
                                      ranged: true,
                                      onChange: updateFilterDate
                                    })
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (enableFilterType.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("div", _hoisted_21, [
                                    _cache[21] || (_cache[21] = _createElementVNode("label", { for: "type" }, "Type", -1)),
                                    (stateFilterCollapsed.value.type)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(stateMenuFilter.value.type.placeholder), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _cache[22] || (_cache[22] = _createElementVNode("div", { class: "col" }, null, -1)),
                                  _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("button", {
                                      class: "fa-regular fa-square-minus",
                                      style: {"padding-right":"10px"},
                                      type: "button",
                                      onClick: _cache[5] || (_cache[5] = ($event: any) => (toggleSelectedFilter('type', false)))
                                    }),
                                    (stateFilterCollapsed.value.type)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          class: "fa-solid fa-chevron-down",
                                          type: "button",
                                          onClick: _cache[6] || (_cache[6] = ($event: any) => (toggleCollapsedFilter('type', false)))
                                        }))
                                      : (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          class: "fa-solid fa-chevron-up",
                                          type: "button",
                                          onClick: _cache[7] || (_cache[7] = ($event: any) => (toggleCollapsedFilter('type', true)))
                                        }))
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_24, [
                              (!stateFilterCollapsed.value.type)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stateFilterData.value.type, (checkbox, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "form-check",
                                        key: index
                                      }, [
                                        _withDirectives(_createElementVNode("input", {
                                          class: "form-check-input",
                                          type: "checkbox",
                                          value: checkbox.value,
                                          id: "flexCheckDefault",
                                          "onUpdate:modelValue": ($event: any) => ((checkbox.checked) = $event),
                                          onChange: ($event: any) => (updateFilterType(checkbox.key, checkbox.checked))
                                        }, null, 40, _hoisted_26), [
                                          [_vModelCheckbox, checkbox.checked]
                                        ]),
                                        _createElementVNode("label", _hoisted_27, _toDisplayString(checkbox.text), 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (enableFilterPage.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            _createElementVNode("div", _hoisted_29, [
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, [
                                  _createElementVNode("div", _hoisted_32, [
                                    _cache[23] || (_cache[23] = _createElementVNode("label", { for: "type" }, "Page", -1)),
                                    (stateFilterCollapsed.value.page)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_33, _toDisplayString(stateMenuFilter.value.page.placeholder), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _cache[24] || (_cache[24] = _createElementVNode("div", { class: "col" }, null, -1)),
                                  _createElementVNode("div", _hoisted_34, [
                                    _createElementVNode("button", {
                                      class: "fa-regular fa-square-minus",
                                      style: {"padding-right":"10px"},
                                      type: "button",
                                      onClick: _cache[8] || (_cache[8] = ($event: any) => (toggleSelectedFilter('page', false)))
                                    }),
                                    (stateFilterCollapsed.value.page)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          class: "fa-solid fa-chevron-down",
                                          type: "button",
                                          onClick: _cache[9] || (_cache[9] = ($event: any) => (toggleCollapsedFilter('page', false)))
                                        }))
                                      : (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          class: "fa-solid fa-chevron-up",
                                          type: "button",
                                          onClick: _cache[10] || (_cache[10] = ($event: any) => (toggleCollapsedFilter('page', true)))
                                        }))
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_35, [
                              (!stateFilterCollapsed.value.page)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stateFilterData.value.page, (checkbox, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "form-check",
                                        key: index
                                      }, [
                                        _withDirectives(_createElementVNode("input", {
                                          class: "form-check-input",
                                          type: "checkbox",
                                          value: checkbox.value,
                                          id: "flexCheckDefault",
                                          "onUpdate:modelValue": ($event: any) => ((checkbox.checked) = $event),
                                          onChange: ($event: any) => (updateFilterPage(checkbox.key, checkbox.checked))
                                        }, null, 40, _hoisted_37), [
                                          [_vModelCheckbox, checkbox.checked]
                                        ]),
                                        _createElementVNode("label", _hoisted_38, _toDisplayString(checkbox.text), 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (enableFilterCompany.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                            _createElementVNode("div", _hoisted_40, [
                              _createElementVNode("div", _hoisted_41, [
                                _createElementVNode("div", _hoisted_42, [
                                  _createElementVNode("div", _hoisted_43, [
                                    _cache[25] || (_cache[25] = _createElementVNode("label", { for: "type" }, "Company Name", -1)),
                                    (stateFilterCollapsed.value.company)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_44, _toDisplayString(stateMenuFilter.value.company.placeholder), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "col" }, null, -1)),
                                  _createElementVNode("div", _hoisted_45, [
                                    _createElementVNode("button", {
                                      class: "fa-regular fa-square-minus",
                                      style: {"padding-right":"10px"},
                                      type: "button",
                                      onClick: _cache[11] || (_cache[11] = ($event: any) => (toggleSelectedFilter('company', false)))
                                    }),
                                    (stateFilterCollapsed.value.company)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          class: "fa-solid fa-chevron-down",
                                          type: "button",
                                          onClick: _cache[12] || (_cache[12] = ($event: any) => (toggleCollapsedFilter('company', false)))
                                        }))
                                      : (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          class: "fa-solid fa-chevron-up",
                                          type: "button",
                                          onClick: _cache[13] || (_cache[13] = ($event: any) => (toggleCollapsedFilter('company', true)))
                                        }))
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_46, [
                              (!stateFilterCollapsed.value.company)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                    _withDirectives(_createElementVNode("select", {
                                      name: "company",
                                      id: "company",
                                      required: "",
                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((stateAPIFilter.value.companyName) = $event)),
                                      onChange: updateFilterCompany
                                    }, [
                                      _cache[27] || (_cache[27] = _createElementVNode("option", {
                                        selected: "",
                                        disabled: "",
                                        value: ""
                                      }, "select company", -1)),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.companyList, (company) => {
                                        return (_openBlock(), _createElementBlock("option", {
                                          key: company.name,
                                          value: company.name
                                        }, _toDisplayString(company.name), 9, _hoisted_48))
                                      }), 128))
                                    ], 544), [
                                      [_vModelSelect, stateAPIFilter.value.companyName]
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])),
              (hideButtonAddFilter.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                    _createElementVNode("div", _hoisted_50, [
                      _createElementVNode("button", {
                        class: "_btn _btn-active",
                        onClick: toggleDropdownAddFilter
                      }, _cache[28] || (_cache[28] = [
                        _createElementVNode("i", { class: "fa-solid fa-plus" }, null, -1),
                        _createTextVNode(" Add Filter ")
                      ])),
                      (isDropdownAddFilterOpen.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stateMenuFilter.value, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", { key: index }, [
                                (!item.selected)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      class: "dropdown-item",
                                      onClick: ($event: any) => (updateMenuFilter(item.key, true))
                                    }, _toDisplayString(item.title), 9, _hoisted_52))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("div", _hoisted_56, [
                    (!enableReset.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                          _createElementVNode("button", {
                            class: "_btn _btn-ghost",
                            onClick: toggleReset
                          }, " Remove All ")
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_58, [
                    _createElementVNode("button", {
                      class: "_btn _btn-ghost",
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (isModalOpen.value = false))
                    }, " Cancel "),
                    _createElementVNode("button", {
                      class: _normalizeClass(`_btn _btn-active ${!enableReset.value ? '' : '_disable'}`),
                      onClick: applyAllFilters
                    }, " Apply Filter ", 2)
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})