import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-title" }
const _hoisted_2 = { class: "page-content" }
const _hoisted_3 = { class: "menu-container" }
const _hoisted_4 = { class: "menu-description" }
const _hoisted_5 = { class: "table side-border-none top-border-none" }
const _hoisted_6 = {
  scope: "col",
  width: "50%"
}
const _hoisted_7 = { class: "pr-2 font-title" }
const _hoisted_8 = {
  scope: "col",
  width: "35%"
}
const _hoisted_9 = { class: "pr-2 font-title" }
const _hoisted_10 = { scope: "row" }
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        class: "btn btn-primary field-submit float-right",
        to: "/dashboard/create_application/hmac_auth"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.applications.create_application")), 1)
        ]),
        _: 1
      }),
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("pages.applications.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("pages.applications.hmac_auth_application_title")), 1)
        ]),
        _createElementVNode("table", _hoisted_5, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("pages.applications.name")), 1)
              ]),
              _createElementVNode("th", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("pages.applications.creation_date")), 1)
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("th", {
                scope: "col",
                width: "15%"
              }, null, -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.applications
                .hmacAuthApplications, (hmacAuthApplication) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: hmacAuthApplication.id
              }, [
                _createElementVNode("td", _hoisted_10, _toDisplayString(hmacAuthApplication.attributes.name), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(hmacAuthApplication.attributes.created_at)), 1),
                _createElementVNode("td", null, [
                  _createElementVNode("a", {
                    href: '/dashboard/applications/' + hmacAuthApplication.id
                  }, _toDisplayString(_ctx.$t("pages.applications.show")), 9, _hoisted_11)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}