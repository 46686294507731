
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SearchInput",
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    placeholder: {
      type: String as PropType<string>,
      default: "Search...",
    },
  },
  data() {
    return {
      localQuery: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.localQuery = newValue;
    },
  },
  methods: {
    onInput(event: Event) {
      const input = event.target as HTMLInputElement;
      this.$emit("update:modelValue", input.value);
      this.$emit("input", input.value);
    },
  },
});
