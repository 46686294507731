import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "_row" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "_row" }
const _hoisted_4 = { class: "_action" }
const _hoisted_5 = ["href"]

import moment from "moment";
import { computed, ref } from "vue";
import DatePickerButton from "./DatePickerButton.vue";
import Modal from "./Modal.vue";

export type ExportData = {
  companySsoId: string;
  date: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportLogButton',
  props: {
    companyList: {}
  },
  emits: ["export"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const isModalOpen = ref(false);
const data = ref<ExportData>({
  companySsoId: "",
  date: moment().format("YYYY-MM-DD"),
});

const enableButton = computed(() => data.value.companySsoId !== "");

const onExport = () => {
  emit("export", data.value);
  isModalOpen.value = false;
};

const onChange = (date: Date) => {
  data.value.date = moment(date).format("YYYY-MM-DD");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "_icon-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isModalOpen.value = true))
    }, _cache[4] || (_cache[4] = [
      _createElementVNode("img", {
        src: "https://cdn-icons-png.flaticon.com/512/3097/3097412.png",
        alt: "export"
      }, null, -1)
    ])),
    _createVNode(Modal, {
      header: "Export activity log",
      "is-modal-open": isModalOpen.value,
      width: "20rem",
      onClose: _cache[3] || (_cache[3] = ($event: any) => (isModalOpen.value = false))
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "date" }, [
            _createTextVNode("Company"),
            _createElementVNode("span", { class: "_required" }, "*")
          ], -1)),
          _withDirectives(_createElementVNode("select", {
            name: "company",
            id: "company",
            required: "",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((data.value.companySsoId) = $event)),
            class: "_input"
          }, [
            _cache[5] || (_cache[5] = _createElementVNode("option", {
              selected: "",
              disabled: "",
              value: ""
            }, "Select company", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.companyList, (company) => {
              return (_openBlock(), _createElementBlock("option", {
                key: company.ssoId,
                value: company.ssoId
              }, _toDisplayString(company.name), 9, _hoisted_2))
            }), 128))
          ], 512), [
            [_vModelSelect, data.value.companySsoId]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("label", { for: "date" }, [
            _createTextVNode("Date"),
            _createElementVNode("span", { class: "_required" }, "*")
          ], -1)),
          _createVNode(DatePickerButton, { onChange: onChange })
        ])
      ]),
      action: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "_btn _btn-ghost",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (isModalOpen.value = false))
          }, " Cancel "),
          _createElementVNode("a", {
            href: `/api/internal/mdc_api/activity_log/export/${data.value.companySsoId}/${data.value.date}`,
            class: _normalizeClass(`_btn _btn-active ${enableButton.value ? '' : '_disable'}`),
            onClick: onExport
          }, " Export ", 10, _hoisted_5)
        ])
      ]),
      _: 1
    }, 8, ["is-modal-open"])
  ], 64))
}
}

})